@import '../styles/variables.scss';
.basic-form {
	&__group {
        align-items: flex-start;
        display: flex;
		margin-bottom: 20px;
    }
    &__text-label {
        width: 200px;
        display: flex;
        justify-content: space-between;
        margin-right: 5px;
        padding: 6px 0;
    }
    &__price {
        font-weight: bold;
    }
	&__text-box,
    &__text-select {
		flex: 1;
		width: 100%;
		border: 1px solid #C6CBB5;
		color: #4A4A4A;
		font-size: 1.6rem;
		line-height: 1.2;
		padding: .8rem 1rem;
        background: #fff;
        border-radius: 1rem;
		resize: vertical;
		margin-bottom: .5rem;
		transition: border-color .2s ease-in;
		&:focus {
			box-shadow: none;
			outline: none;
        }
        &:disabled {
            color: lighten(#979797, 20%);
        }
	}
    &__text-select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='32px' height='32px' fill='%23999999' viewBox='0 0 32 32'%3e%3cpath d='M4.516,6.742L16,18.227L27.484,6.742L31,10.257L16,25.258L1,10.257L4.516,6.742z'/%3e%3c/svg%3e") no-repeat calc(100% - 10px) 50%;
        background-size: 14px;
        &:-moz-focusring {
          color: transparent;
          text-shadow: 0 0 0 #ccc;
        }
    }
	&__group--check {
		position: relative;
	}
	&__group-highlight {
		padding: 5px;
		background-color: $gray-1;
		border-radius: 5px;
		margin-bottom: 1rem;
	}
	&__check-label {
		padding-left: 26px;
	}
	&__check-label-right {
		padding-right: 26px;
	}
	&__check-box {
		position: absolute;
		opacity: 0;
		z-index: 10;
		~ label:before {
			position: absolute;
			left: 0;
			display: block;
			width: 16px;
			height: 16px;
			text-align: center;
			line-height: 15px;
			pointer-events: none;
			content: "";
			user-select: none;
			background: #fff;
			border: 1px solid #979797;
			top: 50%;
			border-radius: 2px;
			transform: translateY(-50%);
			cursor: pointer;
		}
		&:checked ~ label:before {
			content: "";
			color: #fff;
			background: $primary;
			border-color: $primary;
		}
		&:checked ~ label:after {
			content: '';
			position: absolute;
			top: 6px;
			left: 3px;
			width: 10px;
			height: 5px;
			border: 2px solid #fff;
			border-width: 0 0 2px 2px;
			-webkit-transform: rotate(-45deg);
			-ms-transform: rotate(-45deg);
			transform: rotate(-45deg);
		}
	}
	&__check-box-right {
		~ label:before {
			left: initial;
			right: 6px;
		}

		&:checked ~ label:after {
			left: inherit;
			right: 9px;
			top: 11px;
		}
	}
    &__hint {
        color: red;
        font-size: 12px;
        margin: -20px 0 10px;
	}
	&__errors {
		align-items: center;
		background: lighten(red, 50%);
		border: 1px solid red;
		color: red;
		display: flex;
		font-size: 16px;
		margin: 20px 0;
		padding: 10px;
		&__icon {
			margin-right: 7px;
		}
	}
}
.switchToggle:checked + label {
	background: #80b814;
}
.button {
	background: #FFF;
	border: 1px solid $mid-green;
	border-radius: 10px;
	color: $mid-green;
	cursor: pointer;
	font-family: 'Muli', sans-serif;
	font-size: 16px;
	padding: 5px 15px;
	text-transform: uppercase;
	&Save {
		color: #FFF;
		background: $mid-green;
	}
}


.react-datepicker {
	font-size: 1.2rem;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
    margin: 0.2rem;
}

input.checkBoxRegular {
	display: inline;
	height: auto;
	width: auto;
	visibility: visible;
}